import React, { useEffect } from "react"
import { doRedirect } from "@myaccount/utils"
import { useAuthState /* useUserObjectState */ } from "@myaccountServices"
import {
    MYACCOUNT_DASHBOARD,
    MYACCOUNT_MYPROPERTY,
    MYACCOUNT_PROFILE,
    MYACCOUNT_LIGHT,
    RETURN_TOKEN,
} from "@myaccountUrls"
// import { getCall } from "@myaccountServices/store/api"
import { doProfileUpdate } from "@myaccount/helper"
// import { isEmpty } from 'lodash';

export const loginRedirect = (services, state) => {
    if (services.isAuthenticated()) {
        // check for auth Call
        const authParams = services.getData("auth_params")

        // check for auth redirection
        const authReturn = services.getData("auth_return");

        // last login
        services.updateLastLogin()

        if (authReturn) {
            // remove it
            services.removeData("auth_return")
            let token = ""
            if (RETURN_TOKEN && authReturn.match(/(http|https):\/\//)) {
                token = `?jwt=${services.getData("sjwt")}`
            }
            if (authParams) {
                const saveParamsFun = async () => {
                    services.removeData("auth_params")
                    await services.createUserobjectNoState(authParams)
                    doRedirect(`${authReturn}${token}`)
                }
                saveParamsFun()
            } else {
                if (MYACCOUNT_LIGHT && !authReturn.match(/http/))
                    doRedirect(MYACCOUNT_MYPROPERTY)

                doRedirect(`${authReturn}${token}`)
            }
        } else {
            if (authParams) {
                const saveParamsFun = async () => {
                    services.removeData("auth_params")
                    await services.createUserobjectNoState(authParams)
                }
                saveParamsFun()
            }
            // before default auth return happens, do profile check
            if (!MYACCOUNT_LIGHT) {
                const getData = async () => {
                    try {
                        const data = await services.hasPersona()
                        if (!data || doProfileUpdate(state.user)) {
                            services.updateState({ hasPersona: data })
                            doRedirect(MYACCOUNT_PROFILE)
                        } else {
                            doRedirect(MYACCOUNT_DASHBOARD)
                        }
                    } catch (e) {
                        console.log("Error => ", e.response)
                        if (e.response.status === 401) {
                            services.logout()
                        } else {
                            doRedirect(MYACCOUNT_DASHBOARD)
                        }
                    }
                }
                getData()
            } else {
                // // Check for saved search
                // if (!hasSavedSearch(state.user?.userobjects)) {
                //     doRedirect(MYACCOUNT_SAVED_SEARCH)
                // } else {
                    // Default redirect
                    if (process.env.GATSBY_DEFAULT_LANDING_URL) {
                        doRedirect(MYACCOUNT_MYPROPERTY)
                    } else {
                        doRedirect(MYACCOUNT_DASHBOARD)
                    }
                // }
            }
        }
    }

    // return <p>Please Wait...</p>
}

const AuthCallback = props => {
    const { state, services } = useAuthState()
    // const {provider} = props
    // const useUserObjectState = useUserObjectState();

    // const query = new URLSearchParams(window.location.search)
    // const access_token = query.get('raw[access_token]')
    //   useEffect(() => {
    //       async function fetchData() {
    //           let data;
    //           if(provider === 'google') {
    //               data = await getCall(`https://www.googleapis.com/oauth2/v3/userinfo?alt=json&access_token=${access_token}`)
    //               let img = data.data.pictures;
    //           }
    //
    //           console.log("Data => ", data.data)
    //       }
    //       fetchData();
    //   }, [state.user]); // Or [] if effect doesn't need props or state

    useEffect(() => {
            services.providerAuthLogin(props.provider, props.location.search)
    }, [props.location.search]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(!state.user?.blocked && state.user?.confirmed) {
            loginRedirect(services, state)
        }
    }, [state.user]) // eslint-disable-line react-hooks/exhaustive-deps

    return <p>Please Wait...</p>
}

export default AuthCallback
